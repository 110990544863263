<template>
  <div>
    <div class="overmask"></div>
    <div
      id="Login"
      class="containerLogin"
      :class="getClassBackground()"
    >
      <div
        v-if="carregando == true"
        class="carregando"
      >
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>

      <div>
        <b-row
          class="mt-5 text-center"
          v-if="erroConexao != ''"
        >
          <b-col>
            <div class="logo">
              <h1 style="max-width: 90%">Clube Certo</h1>
            </div>
            <div class="alert alert-warning">{{ erroConexao }}</div>
            <b-button @click="buscaDadosEmpresa()">Tentar Novamente</b-button>
          </b-col>
        </b-row>
        <b-row
          v-else
          class="mt-5 d-flex flex-column"
          style="height: auto"
        >
          <h2 style="color: white; font-size: 25px; text-align: center; font-weight: 800">
            O melhor Clube de <br />
            Descontos do Brasil
          </h2>
          <p style="color: white; text-align: center; font-size: 16px">Tudo em um só app pra facilitar <br />seu dia a dia e para você enconomizar</p>

          <b-col
            class="text-center d-flex flex-column"
            style="background-color: white; border-radius: 80px 80px 0 0"
          >
            <div class="logo">
              <h2 style="max-width: 60%; margin: auto; margin-top: 30px">Insira seu CPF</h2>
            </div>

            <b-form
              @submit.stop.prevent="onSubmit"
              class="container"
            >
              <div>
                <b-form-group
                  id="input-group-2"
                  label
                  label-for="input-2"
                >
                  <the-mask
                    style="text-align: center"
                    type="text"
                    class="form-control"
                    v-model="form.cpfcnpj"
                    v-validate="{ required: true }"
                    :placeholder="'CPF / CNPJ'"
                    :mask="['###.###.###-##', '##.###.###/####-##']"
                    id="cpfcnpj"
                    name="CPF / CNPJ"
                    :masked="true"
                    :state="validateState('CPF / CNPJ')"
                    :class="validateClass('CPF / CNPJ')"
                  ></the-mask>
                </b-form-group>
              </div>
              <b-button
                type="submit"
                class="btn-block mt-3"
                variant="success"
                >Entrar</b-button
              >
            </b-form>
            <a
              href="https://clubecerto.com.br/contato"
              class="linkAjuda"
              >Precisa de ajuda?</a
            >
          </b-col>
        </b-row>
      </div>
    </div>
    <b-modal
      v-model="modalEmpresas"
      hide-footer
      centered
      title="Selecione uma Empresa para Efetuar Login"
      ><div class="mb-5">
        <b-card
          v-for="item in empresas"
          :key="item.id"
          no-body
          class="overflow-hidden mb-3"
        >
          <b-row no-gutters>
            <b-col class="text-center p-5">
              <img
                :src="item.Empresa.Imagem"
                :alt="item.Empresa.Nome"
                class="rounded-0"
                width="70%"
                @click="selecionaEmpresa(item)"
              />
            </b-col>
          </b-row>
        </b-card>
      </div>
    </b-modal>
  </div>
</template>
<!-- eslint-disable -->
<script>
import locale from "./../locale";
import service from "../services";
import jwt from "jsonwebtoken";
export default {
  name: "DashBoard",
  props: {
    msg: String,
  },
  watch: {},
  data() {
    return {
      form: {
        cpfcnpj: null,
      },
      cpf: null,
      cpfValidado: false,
      validacaoCpf: {},
      erroConexao: "",
      carregando: false,
      trocouLogo: false,
      empresas: [],
      modalEmpresas: false,
      value: 80,
      max: 100,
      idEmpresa: null,
      temaCor: {
        botaoLogin: "#000000",
        alphaLogin: "#000000",
        botaoFormularios: "#000000",
      },
    };
  },
  components: {},
  methods: {
    validaCpfBase() {
      if (this.cpf.length >= 14)
        return new Promise(async (resolve, reject) => {
          this.carregando = true;
          this.form.cpfcnpj = this.cpf;
          this.form.idEmpresa = this.idEmpresa;
          service
            .post("Login", `validaCpf`, this.form)
            .then((res) => {
              this.cpfValidado = true;
              this.validacaoCpf = res.data;
              this.carregando = false;
              resolve();
            })
            .catch((e) => {
              this.carregando = false;
              reject();
            });
        });
    },
    isMobile() {
      if (process.env.VUE_APP_ENV == "DEV") {
        return true;
      }
      if (/Mobile|mini|Fennec|Android|iP(ad|od|hone)/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        if (!this.veeErrors.has(ref) == false) {
          return "border border-danger ";
        } else {
          return "border border-success ";
        }
      }
      return null;
    },
    getClassBackground() {
      if (!this.$route.query.cpf) {
        return "container-with-bg";
      }
      return "";
    },
    popToast({ message }) {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var msg = "";
      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h("p", {}, element));
      });
      // Create the message
      const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: message || "Atenção!",
        solid: true,
        variant: "danger",
        toaster: "b-toaster-top-full",
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    selecionaEmpresa(item) {
      localStorage.setItem("nome", item.Nome);
      localStorage.setItem("cpf", item.CPF);
      localStorage.setItem("dadosEmpresa", JSON.stringify(item.Empresa));
      this.$router.push({
        path: "dashboard",
        query: { plan: new Date().toString() },
      });
    },
    onSubmit(evt) {
      this.idEmpresa = this.$route.query.companyId;
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.popToast();
          return;
        }
        this.carregando = true;
        var action = "makeLogin";

        service
          .post("Login", action, this.form)
          .then((res) => {
            this.carregando = false;
            if (res.data.token) {
              localStorage.setItem("user-token", res.data.token);
              var decoded = jwt.decode(res.data.token);
            }
            var empresas = res.data.dados.filter((x) => x.Ativo == "1");
            if (empresas.length > 0) {
              var cpf = localStorage.getItem("cpf");
              if (cpf)
                if (cpf != this.form.cpfcnpj) {
                  localStorage.removeItem("dadosEmpresa");
                  localStorage.removeItem("codigoEstado");
                  localStorage.removeItem("latitude");
                  localStorage.removeItem("longitude");
                  localStorage.removeItem("estado");
                }
            }
            if (empresas.length == 1) {
              if (this.idEmpresa) {
                if (empresas[0].Empresa.Codigo != this.idEmpresa) {
                  this.$bvToast.toast("Falha ao realizar login", {
                    title: "Atenção!",
                    solid: true,
                    variant: "danger",
                    toaster: "b-toaster-top-full",
                    appendToast: false,
                    autoHideDelay: 2500,
                  });
                  return;
                }
              }
              localStorage.setItem("nome", empresas[0].Nome);
              localStorage.setItem("cpf", empresas[0].CPF);
              localStorage.setItem("dadosEmpresa", JSON.stringify(empresas[0].Empresa));
              this.$router.push({
                path: "dashboard",
                query: { plan: new Date().toString() },
              });
            } else if (empresas.length == 0) {
              this.$bvToast.toast("Usuário inativo no sistema", {
                title: "Atenção!",
                solid: true,
                variant: "danger",
                toaster: "b-toaster-top-full",
                appendToast: false,
                autoHideDelay: 2500,
              });
              this.empresas = empresas;
              localStorage.setItem("empresas", JSON.stringify(this.empresas));
            } else {
              this.empresas = empresas;
              localStorage.setItem("empresas", JSON.stringify(this.empresas));

              if (this.idEmpresa) {
                const empresaSelecionada = empresas.find((empresa) => empresa.Empresa.Codigo == this.idEmpresa);
                if (empresaSelecionada) {
                  this.selecionaEmpresa(empresaSelecionada);
                  return;
                }
              }
              this.modalEmpresas = true;
            }
          })
          .catch((e) => {
            this.carregando = false;
            this.$bvToast.toast(e, {
              title: "Atenção!",
              solid: true,
              variant: "danger",
              toaster: "b-toaster-top-full",
              appendToast: false,
              autoHideDelay: 2500,
            });
          });
      });
    },
    validaToken() {
      const token = localStorage.getItem("user-token");
      if (token) {
        const decoded = jwt.decode(token);
        service
          .validaToken(token, "Login", "ValidateToken")
          .then((res) => {
            if (res.data.valid == true) {
              const decoded = jwt.decode(token);
              this.form.cpfcnpj = decoded.cpfcnpj;
              this.onSubmit();
              // this.$router.push({
              //   path: "dashboard",
              //   query: { plan: new Date().toString() },
              // });
            }
          })
          .catch((err) => {
            console.log(err);
            localStorage.removeItem("user-token");
          });
      } else {
        localStorage.removeItem("user-token");
        if (this.$route.query.cpf) {
          this.cpf = this.$route.query.cpf;
          this.form.cpfcnpj = this.$route.query.cpf;
          this.idEmpresa = this.$route.query.idEmpresa;
          this.onSubmit();
        }
      }
    },
  },
  created() {},
  mounted() {
    this.$validator.localize("en", locale);
    this.validaToken();

    localStorage.removeItem("dadosEmpresa");
    if (this.$route.query.showHeader) localStorage.setItem("showHeader", this.$route.query.showHeader);
    if (this.$route.query.showFooter) localStorage.setItem("showFooter", this.$route.query.showFooter);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logoRodape {
  text-align: center;
  margin-top: 15px;
  font-size: 12px;
  display: block;
}
.containerLogin {
  position: relative;
  width: 100%;
  height: 92vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: "roboto", sans-serif;
}

.container-with-bg {
  background-image: url("../assets/images/Fundo_App_M2-1080x1920_Pessoa_Login-CPF.jpg");
  background-size: cover;
  background-position: center;
}
.nav-link {
  display: block;
  padding: 0.5rem 0.5rem;
  font-family: "roboto", sans-serif;
}
/*
#Login {
  background-image: url("./../assets/images/1.png");
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-size: cover;
}
*/
.logo {
  background-color: white;
  border-radius: 100px;
  font-family: "roboto", sans-serif;
}

.texto-placa {
  margin-top: 5px;
  font-size: 13px;
  font-family: "roboto", sans-serif;
}
.link-senha {
  font-size: 13px;
  text-decoration: underline;
  font-family: "roboto", sans-serif;
}
.linkAjuda {
  text-align: right;
  width: 90%;
  margin-top: 20px;
  color: black;
  text-decoration: underline;
  font-weight: 600;
  font-family: "roboto", sans-serif;
}
</style>
<style>
body.home {
  background-image: url("./../assets/images/5.png");
  width: 100vw;
  height: 100vh;
  min-height: 100%;
  background-size: cover;
}
</style>
